const { default: SignaturePad } = require('signature_pad');

require('./bootstrap');
var userDocumentCount = $('#documentsRecordsCount').val() ? $('#documentsRecordsCount').val() - 1 : 0;
/**
 * add new user document section trigger on click function on application form
 */

$('#add-new-user-document').on('click', function () {
    userDocumentCount++;
    $('.dynamic-user-document-div').append(`
        <section class="previous-document-section">
            <div class="row d-flex">
                <div class="col-md-6">
                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" name="document_name[]" value="" placeholder="Documents Name">
                </div>
                <div class="col-md-6">
                    <label for="formFile" class="form-label">Upload Document</label>
                    <input class="form-control" type="file" id="formFile" name="document_images[]">
                </div>
            </div>
            <hr />
        </section>
    `);
});

$('#remove-new-user-document').on('click', function () {
    $('.dynamic-user-document-div section.previous-document-section:last').remove();
    userDocumentCount--;
});

var userResidenceCount = $('#userResidencesCount').val() ? $('#userResidencesCount').val() - 1 : 0;

/**
 * add new user residence section trigger on click function on application form
 */
$('#add-new-user-residence').on('click', function () {
    userResidenceCount++;
    $('.dynamic-user-residance-div').append(`
        <section class="previous-residance-section">
            <div class="row">
                <div class="form-group col-md-8">
                    <label for="street">Street Address</label>
                    <input type="text" class="form-control" id="street" name="street[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="city">City</label>
                    <input type="text" class="form-control" id="city" name="city[]"
                        value="">
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-4">
                    <label for="state">State</label>
                    <input type="text" class="form-control" id="state" name="state[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="zipCode">Zip Code</label>
                    <input type="number" class="form-control" id="zipCode" name="zip_code[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="numberOfYears"># of Years at Address</label>
                    <input type="number" class="form-control" id="numberOfYears"
                        name="no_years_at_this_address[]"
                        value="">
                </div>
            </div>
            <br />
            <fieldset class="row ">
                <p class="col-form-label col-md-3 pt-0 pull-left float-left">What type of
                    address is this ?</p>
                <div class="col-md-9 pull-right float-right">
                    <section class="row">
                        <div class="form-check col-3 m-0">
                            <input class="form-check-input" type="radio" name="address_type[`+ userResidenceCount + `]"
                                id="addressTypeRadio1" value="current" checked>
                            <label class="form-check-label" for="addressTypeRadio1">
                                Current
                            </label>
                        </div>
                        <div class="form-check col-3 m-0">
                            <input class="form-check-input" type="radio" name="address_type[`+ userResidenceCount + `]"
                                id="addressTypeRadio1" value="mailing">
                            <label class="form-check-label" for="addressTypeRadio1">
                                Mailing
                            </label>
                        </div>
                        <div class="form-check col-3 m-0">
                            <input class="form-check-input" type="radio" name="address_type[`+ userResidenceCount + `]"
                                id="addressTypeRadio1" value="previous">
                            <label class="form-check-label" for="addressTypeRadio1">
                                Previous
                            </label>
                        </div>
                    </section>
                </div>
            </fieldset>
            <hr />
        </section>
    `);
});

/**
 * remove a user residence section trigger on click function on application form
 */
$('#remove-new-user-residence').on('click', function () {
    $('.dynamic-user-residance-div section.previous-residance-section:last').remove();
    userResidenceCount--;
});

var userLicensesCount = $('#userLicensesCount').val() ? $('#userLicensesCount').val() - 1 : 0;

/**
 * add new user license section trigger on click function on application form
 */
$('#add-new-license-information').on('click', function () {
    userLicensesCount++;
    $('.dynamic-license-div').append(`
        <section class="license-information-section">
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="licenseState">State</label>
                    <input type="text" class="form-control" id="licenseState" name="license_state[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="licenseNo">License #</label>
                    <input type="text" class="form-control" id="licenseNo" name="license_no[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="typeClass">Type/Class</label>
                    <input type="text" class="form-control" id="typeClass" name="license_class_type[]"
                        value="">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="issueDate">Issue Date</label>
                    <input type="date" class="form-control" id="issueDate" name="issue_date[]" value="">
                </div>
                <div class="form-group col-md-6">
                    <label for="expirationDate">Expiration Date</label>
                    <input type="date" class="form-control" id="expirationDate" name="expiration_date[]" value="">
                </div>
                <div class="form-group col-md-6">
                    <label for="endorsement">Endorsement</label>
                    <input type="text" class="form-control" id="endorsement" name="endorsement[]" value="">
                </div>
            </div>
            <br />
            <fieldset class="row ">
                <p class="col-form-label col-md-4 pt-0 pull-left float-left">What type of
                    license is this ?</p>
                <div class="col-md-8 pull-right float-right">
                    <section class="row">
                        <div class="form-check col-4 m-0">
                            <input class="form-check-input" type="radio" name="license_type[`+ userLicensesCount + `]"
                                id="gridRadios1" value="current"
                            checked>
                            <label class="form-check-label" for="gridRadios1">
                                Current
                            </label>
                        </div>
                        <div class="form-check col-4 m-0">
                            <input class="form-check-input" type="radio" name="license_type[`+ userLicensesCount + `]"
                                id="gridRadios2" value="previous">
                            <label class="form-check-label" for="gridRadios2">
                                Previous
                            </label>
                        </div>
                    </section>
                </div>
            </fieldset>
            <hr />
        </section>
    `);
});

/**
 * remove a user license section trigger on click function on application form
 */
$('#remove-new-license-information').on('click', function () {
    $('.dynamic-license-div section.license-information-section:last').remove();
    userLicensesCount--;
});
/****
 * add new user equipment information
 */
var userEquipmentCount = $('#userEquipmentCount').val() ? $('#userEquipmentCount').val() - 1 : 0;
$('#add-new-equipment-information').on('click',function(){
    userEquipmentCount++;
    $('.dynamic-equipment-div').append(`<section class="equipment-information-section">
    <div class="row">
        <div class="form-group col-md-4">
            <label for="equipmentState">State</label>
            <input type="text" class="form-control" id="equipmentState"
                name="equipment_state[]" value="">
        </div>
        <div class="form-group col-md-4">
            <label for="licensePlate">License Plate</label>
            <input type="text" class="form-control" id="licensePlate" name="license_plate[]"
                value="">
        </div>
        <div class="form-group col-md-4">
            <label for="equipmenttypeClass">Type/Class</label>
            <input type="text" class="form-control" id="equipmenttypeClass"
                name="equipment_class_type[]" placeholder="Class 8" value="">
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label for="vin">Vehilcle Identification Number</label>
            <input type="text" class="form-control" id="vin" name="vin[]" value="">
        </div>
        <div class="form-group col-md-6">
            <label for="equipmentExpirationDate">Expiration Date</label>
            <input type="date" class="form-control" id="equipmentExpirationDate"
                name="equipment_expiration_date[]" value="">
        </div>
        <div class="form-group col-md-6">
            <label for="equipmentEndorsement">Endorsement</label>
            <input type="text" class="form-control" id="equipmentEndorsement" name="equipment_endorsement[]" value="" placeholder="Make,model,year,mileage">
        </div>
    </div>
    <br />
    <fieldset class="row ">
        <p class="col-form-label col-md-4 pt-0 pull-left float-left">Apportioned Tag?</p>
        <div class="col-md-8 pull-right float-right">
            <section class="row">
                <div class="form-check col-4 m-0">
                    <input class="form-check-input" type="radio"
                    name="apportioned_tag[` + userEquipmentCount + `]" id="tagradio1"
                    value="1">
                    <label class="form-check-label" for="tagradio1">
                        Yes
                    </label>
                </div>
                <div class="form-check col-4 m-0">
                    <input class="form-check-input" type="radio"
                            name="apportioned_tag[` + userEquipmentCount + `]" id="tagradio2"
                            value="0"> 
                    <label class="form-check-label" for="tagradio2">
                        No
                    </label>
                </div>
            </section>
        </div>
    </fieldset>
    <hr />
</section>`);
});

/***
 * Remove user Equipment info
 */
$('#remove-new-equipment-information').on('click',function(){
    $('.dynamic-equipment-div section.equipment-information-section:last').remove();
    userEquipmentCount--;
});



var drivingExperienceCount = $('#drivingExperienceCount').val() ? $('#drivingExperienceCount').val() - 1 : 0;

/**
 * add new expereince section trigger on click function on application form
 */
$('#add-new-expereince').on('click', function () {
    drivingExperienceCount++;
    $('.dynamic-experience-div').append(`
        <section class="drivingExperienceCardBody">
            <div class="row">
                <section class="form-group col-md-5">
                    <label for="vehicleClass">Class of Equipment</label>
                    <select class="form-select" id="vehicleClass" name="class_of_equipment[]"
                        aria-label="Default select example">
                        <option disabled selected value> -- select an option -- </option>
                        <option value="tractor and semi trailer">SEMI-TRAILER & TRACTOR</option>
                        <option value="straight truck">STRAIGHT TRUCK</option>
                        <option value="tractor and 2 trailers">TRACTOR & 2 TRAILERS</option>
                        <option value="tractor and tanker">TRACTOR & TANKER</option>
                        <option value="other">OTHER</option>
                    </select>
                </section>
                <div class="form-group col-md-5">
                    <label for="equipmentType">Type of Equipment (Van, Tank, Flat,
                        Etc.)</label>
                    <input type="text" class="form-control" id="equipmentType"
                        name="type_of_equipment[]">
                </div>
                <div class="form-group col-md-2">
                    <label for="approxMiles">Approx # of Miles (Total)</label>
                    <input type="text" class="form-control" step="any" id="approxMiles"
                        name="approx_no_of_miles[]">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="driveFrom">Date from</label>
                    <input type="month" class="form-control" id="driveFrom"
                        name="experience_from[]">
                </div>
                <div class="form-group col-md-6">
                    <label for="driveTo">Date to</label>
                    <input type="month" class="form-control" id="driveTo" name="experience_to[]">
                </div>
            </div>
            <hr />
        </section>
    `);
});

/**
 * remove an expereince section trigger on click function on application form
 */
$('#remove-new-expereince').on('click', function () {
    $('.dynamic-experience-div section.drivingExperienceCardBody:last').remove();
    drivingExperienceCount--;
});

var accidentHistoryCount = $('#accidentHistoryCount').val() ? $('#accidentHistoryCount').val() - 1 : 0;

/**
 * add new user accident record section trigger on click function on application form
 */
$('#add-new-accident-record').on('click', function () {
    accidentHistoryCount++;
    $('.dynamic-accident-record-div').append(`
        <section class="accident-history-section">
            <div class="row">
                <div class="form-group input-group-sm col-md-4">
                    <label for="accidentDate">Date</label>
                    <input type="date" class="form-control" id="accidentDate" name="accident_date[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="accidentNature">Nature of Accident (Head-on, rear-end,
                        upset, etc.)</label>
                    <input type="text" class="form-control" id="accidentNature" name="accident_nature[]"
                        value="">
                </div>
                <div class="form-group col-md-2">
                    <label for="accidentFatalities"># Fatalities</label>
                    <input type="number" class="form-control" min="0" id="accidentFatalities"
                        name="no_of_fatalities[]"
                        value="">
                </div>
                <div class="form-group col-md-2">
                    <label for="accidentInjuries"># Injuries</label>
                    <input type="number" class="form-control" min="0" id="accidentInjuries"
                        name="no_of_injuries[]"
                        value="">
                </div>
            </div>
            <br />
            <fieldset class="row ">
                <p class="col-form-label col-md-4 pt-0 pull-left float-left">Chemical
                    Spills ?</p>
                <div class="col-md-8 pull-right float-right">
                    <section class="row">
                        <div class="form-check col-4 m-0">
                            <input class="form-check-input" type="radio" name="chemical_spills[`+ accidentHistoryCount + `]"
                                id="gridRadios1" value="1">
                            <label class="form-check-label" for="gridRadios1">
                                yes
                            </label>
                        </div>
                        <div class="form-check col-4 m-0">
                            <input class="form-check-input" type="radio" name="chemical_spills[`+ accidentHistoryCount + `]"
                                id="gridRadios2" value="0" checked>
                            <label class="form-check-label" for="gridRadios2">
                                No
                            </label>
                        </div>
                    </section>
                </div>
            </fieldset>
            <hr />
        </section>
    `);
});

/**
 * remove a user accident record section trigger on click function on application form
 */
$('#remove-new-accident-record').on('click', function () {
    $('.dynamic-accident-record-div section.accident-history-section:last').remove();
    accidentHistoryCount--;
});

var trafficConvictionsCount = $('#trafficConvictionsCount').val() ? $('#trafficConvictionsCount').val() - 1 : 0;

/**
 * add new user traffic conviction section trigger on click function on application form
 */
$('#add-new-traffic-conviction').on('click', function () {
    trafficConvictionsCount++;
    $('.dynamic-traffic-conviction-div').append(`
        <section class="traffic-conviction-section">
            <div class="row">
                <div class="form-group input-group-sm col-md-3">
                    <label for="convictionDate">Date Convicted</label>
                    <input type="date" class="form-control" id="convictionDate" name="date_convicted[]"
                        value="">
                </div>
                <div class="form-group col-md-9">
                    <label for="violation">Violation</label>
                    <input type="text" class="form-control" id="violation" name="violation[]"
                        value="">
                </div>
            </div>
            <div class="row">
                <div class="form-group input-group-sm col-md-3">
                    <label for="violationState">Violation State</label>
                    <input type="text" class="form-control" id="violationState" name="violation_state[]"
                        value="">
                </div>
                <div class="form-group col-md-9">
                    <label for="penalty">Penalty (Forfeited bond, collateral and/or
                        points)</label>
                    <input type="text" class="form-control" id="penalty" name="penalty[]"
                        value="">
                </div>
            </div>
            <hr />
        </section>
    `);
});

/**
 * remove a user traffic conviction section trigger on click function on application form
 */
$('#remove-new-traffic-conviction').on('click', function () {
    $('.dynamic-traffic-conviction-div section.traffic-conviction-section:last').remove();
    trafficConvictionsCount--;
});


var educationRecordsCount = $('#educationRecordsCount').val() ? $('#educationRecordsCount').val() - 1 : 0;

/**
 * add new user education section trigger on click function on application form
 */
$('#add-new-education-history').on('click', function () {
    educationRecordsCount++;
    $('.dynamic-education-div').append(`
        <section class="education-history-section">
            <div class="row">
                <section class="form-group col-md-4">
                    <label for="educationType">School</label>
                    <select class="form-select" id="educationType" name="education_type[]"
                        aria-label="Default select example">
                        <option value="high school" selected>
                            High School</option>
                        <option value="college">
                            College</option>
                        <option value="other">
                            Other</option>
                    </select>
                </section>
                <div class="form-group col-md-8">
                    <label for="institueName">Name & Location</label>
                    <input type="text" class="form-control" id="institueName" name="institute_name[]"
                        value="">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-8">
                    <label for="courseOfStudy">Course of Study</label>
                    <input type="text" class="form-control" id="courseOfStudy" name="course_of_study[]"
                        value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="yearsCompleted">Years Completed</label>
                    <input type="number" class="form-control" id="yearsCompleted" name="years_completed[]"
                        value="">
                </div>
            </div>
            <br />
            <fieldset class="row ">
                <p class="col-form-label col-md-3 pt-0 pull-left float-left">Graduated ?
                </p>
                <div class="col-md-9 pull-right float-right">
                    <section class="row">
                        <div class="form-check col-2 m-0">
                            <input class="form-check-input" type="radio" name="graduated[`+ educationRecordsCount + `]" id="gridRadios1"
                                value="1" checked>
                            <label class="form-check-label" for="gridRadios1">
                                yes
                            </label>
                        </div>
                        <div class="form-check col-2 m-0">
                            <input class="form-check-input" type="radio" name="graduated[`+ educationRecordsCount + `]" id="gridRadios2"
                                value="0">
                            <label class="form-check-label" for="gridRadios2">
                                No
                            </label>
                        </div>
                    </section>
                </div>
            </fieldset>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="educationDetail">Details</label>
                    <textarea class="form-control" name="education_details[]" id="educationDetail"
                        rows="3"></textarea>
                </div>
            </div>
            <hr />
        </section>
    `);
});

/**
 * remove a user education section trigger on click function on application form
 */
$('#remove-new-education-history').on('click', function () {
    $('.dynamic-education-div section.education-history-section:last').remove();
    educationRecordsCount--;
});


var employmentHistoryCount = $('#employementHistoryCount').val() ? $('#employementHistoryCount').val() - 1 : 0;

/**
 * add new employment history section trigger on click function on application form
 * 
 */
{/* <input type="radio" name="search_employe`+ employmentHistoryCount +`" id="search_local" value="local" class="search_employe_local">
                        <label class="search" for="search_local">Search Locally</label> */}

                        
                    // <input type="radio" name="search_employe`+ employmentHistoryCount +`" id="search_global" value="global" class="search_employe_global" checked=true>
                    // <label class="search" for="search_global">Search</label>



                    // <select class="js-example-basic-multiple js-states form-control employerName js-data-example-ajax" id="employerName-`+ employmentHistoryCount +`"
                    //     name="employer_name[]" placeholder="Type to search...">
                    //     <option value=''></option>
                    // </select>


                    // <input type="hidden" class="form-control mt-2" id="dotNo" name="dot_no[]" value=""
                    // placeholder="xxxxxx" readonly></input>
    
    
$('#add-new-employment-history').on('click', function () {
    employmentHistoryCount++;
    $('.dynamic-employment-history-div').append(`
        <section class="employmentHistoryCardBody content myDiv" id=`+ employmentHistoryCount+`>
            <div class="spinner"  id="spin`+ employmentHistoryCount+`" style="display: none">
                <div class="spinner-inner"></div>
            </div>
            
            <div class="row ">
             
                <div class="form-group col-md-12 mt-3">
                    <div class="mb-3">

                    <div class="row content" style="background: #80808069; margin-left: 0px; margin-right: 0;padding: 10px 0;">
                            <div id="spinner`+ employmentHistoryCount+`" class="spinner" style="display: none">
                                <div class="spinner-inner"></div>
                            </div>
                            <div>
                                <label style="color: #4a3e3e;font-family: 'Nunito', sans-serif;font-weight: 400">We are searching millions of records; loading can take a minute.</label>
                            </div>
                            <div class="col-md-5">
                       
                                <label for="employerName" class="form-label">Name</label>
                                <input name="employer_name[]" id="employerName`+ employmentHistoryCount+`"
                                    class="form-control  search_name" type="search">
                            </div>
                            <div class="col-md-5">
                                <label for="employerCity" class="form-label">City</label>

                                <input name="employerCity[]" id="employerCity`+ employmentHistoryCount +`"
                                    class="form-control search_city" type="search">
                            </div>
                            <div class="col-md-2">
                            <button type="button" class="btn  btn-success searchbtn`+ employmentHistoryCount +`"
                                style="margin-top: 2rem">Search</button>
                            </div>
                            <div class="col-md-12" data-ulid="`+ employmentHistoryCount +`">
                                <ul class="data" name="data" id="data`+employmentHistoryCount+`">
                                </ul>
                            </div>
                    </div>
                     
                    </div>
                </div>
                <div class="form-group col-md-12">
                <label for="manualEmployerName" style="font-family: 'Nunito', sans-serif">If the search does not find Employer's Name, please enter below.</label>
                <input type="text" class="form-control mt-2" id="manualEmployerName`+ employmentHistoryCount +`" name="manual_employer_name[]"
                placeholder="If the search does not find Employer's Name, please enter here">
                </div>

                <div>
                    
                    <input type="hidden" id="dotStreet" name="dot_street[]" value="">
                    <input type="hidden" id="dotCity`+ employmentHistoryCount + `" name="dot_city[]" value="">
                    <input type="hidden" id="dotState" name="dot_state[]" value="">
                    <input type="hidden" id="dotZip" name="dot_zip[]" value="">
                    <input type="hidden" id="dotCountry" name="dot_country[]" value="">
                </div>

                <div class="mt-2 mb-2 form-group col-md-4 ">
                    <label>Subject to FMCSA: </label>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="subject_to_fmcsa[`+ employmentHistoryCount + `]" id="fmcsa1`+ employmentHistoryCount + `"
                            value="1">
                        <label class="form-check-label" for="fmcsa1`+ employmentHistoryCount + `">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="subject_to_fmcsa[`+ employmentHistoryCount + `]" id="fmcsa2`+ employmentHistoryCount + `"
                            value="0" checked>
                        <label class="form-check-label" for="fmcsa2`+ employmentHistoryCount + `">No</label>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-4">
                    <label for="employerAddress">Address</label>
                    <input type="text" class="form-control" id="employerAddress`+ employmentHistoryCount +`" name="employer_address[]">
                </div>
                <div class="form-group col-md-4">
                    <label for="employerPhone">Phone</label>
                    <input type="text" class="form-control" id="employerPhone`+ employmentHistoryCount +`" name="employer_phone[]" placeholder="(xxx) xxx-xxxx">
                </div>
                <div class="form-group col-md-4">
                    <label for="employerPhone">Email</label>
                     <input type="text" class="form-control" id="employerEmail`+ employmentHistoryCount +`" name="employer_email[]">
                 </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="dotNo">DOT Number or Customer ID</label>
                    <input type="text" class="form-control" id="dotNo`+employmentHistoryCount+`" name="dot_no[]">
                </div>
                <div class="form-group col-md-6">
                    <label for="positionHeld">Position Held</label>
                    <input type="text" class="form-control" id="positionHeld" name="position_held[]">
                </div>
                <div class="form-group input-group-sm col-md-3">
                    <label for="employementFrom">From</label>
                    <input type="month" class="form-control" id="employementFrom" name="employement_from[]">
                </div>
                <div class="form-group input-group-sm col-md-3">
                    <label for="employementTo">To</label>
                    <input type="month" class="form-control" id="employementTo" name="employement_to[]">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-10">
                    <label for="reasonForLeaving">Reason for Leaving</label>
                    <input type="text" class="form-control" id="reasonForLeaving"
                        name="reason_for_leaving[]">
                </div>
                <div class="form-group col-md-2">
                    <label for="salary">Salary</label>
                    <input type="text" class="form-control" id="salary" name="salary[]">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="employmentGap">Explain any gaps in Employment (Include
                        month/year & reason)</label>
                    <textarea class="form-control" name="explain_any_gaps[]" id="employmentGap" rows="3"></textarea>
                </div>
            </div>
            <br />
            <fieldset class="row ">
                <p class="col-form-label col-md-8 pt-0 pull-left float-left">While employed
                    here, were you subject to the Federal Motor Carrier Safety Regulations?
                </p>
                <div class="col-md-4 pull-right float-right">
                    <section class="row">
                        <div class="form-check col-2 m-0">
                            <input class="form-check-input" type="radio"
                                name="subject_to_FMC_safety_regulations[`+ employmentHistoryCount + `]" id="gridRadios1" value="1">
                            <label class="form-check-label" for="gridRadios1">
                                yes
                            </label>
                        </div>
                        <div class="form-check col-2 m-0">
                            <input class="form-check-input" type="radio"
                                name="subject_to_FMC_safety_regulations[`+ employmentHistoryCount + `]" id="gridRadios2" value="0" checked>
                            <label class="form-check-label" for="gridRadios2">
                                No
                            </label>
                        </div>
                    </section>
                </div>
            </fieldset>
            <fieldset class="row ">
                <p class="col-form-label col-md-8 pt-0 pull-left float-left">Was the job
                    designated as a safety-sensitive function in any Department of
                    Transportation-regulated
                    mode subject to alcohol and controlled substances testing as required by
                    49 CFR, part 40?</p>
                <div class="col-md-4 pull-right float-right">
                    <section class="row">
                        <div class="form-check col-2 m-0">
                            <input class="form-check-input" type="radio"
                                name="safety_sensitive_required_49_CFR[`+ employmentHistoryCount + `]" id="gridRadios1" value="1">
                            <label class="form-check-label" for="gridRadios1">
                                yes
                            </label>
                        </div>
                        <div class="form-check col-2 m-0">
                            <input class="form-check-input" type="radio"
                                name="safety_sensitive_required_49_CFR[`+ employmentHistoryCount + `]" id="gridRadios2" value="0" checked>
                            <label class="form-check-label" for="gridRadios2">
                                No
                            </label>
                        </div>
                    </section>
                </div>
            </fieldset>
            <hr />
        </section>
    `);

    $(document).on('input', '#employerPhone'+employmentHistoryCount, function (e) {
        var $phoneField = e.target;
        var cursorPosition = $phoneField.selectionStart;
        var numericString = $phoneField.value.replace(/\D/g, '').substring(0, 10);

        // let user backspace over the '-'
        if (cursorPosition === 9 && numericString.length > 6) return;

        // let user backspace over the ') '
        if (cursorPosition === 5 && numericString.length > 3) return;
        if (cursorPosition === 4 && numericString.length > 3) return;

        var match = numericString.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
            if (match) {
                var newVal = '(' + match[1];
                newVal += match[2] ? ') ' + match[2] : '';
                newVal += match[3] ? '-' + match[3] : '';

                // to help us put the cursor back in the right place
                var delta = newVal.length - Math.min($phoneField.value.length, 14);
                $phoneField.value = newVal;
                $phoneField.selectionEnd = cursorPosition + delta;
            } else {
                $phoneField.value = '';
            }
    })
    
    // $('.search_name'+ employmentHistoryCount).on('keyup',function(){
    //     $('.search_city'+ employmentHistoryCount).val('');
    // })

    // $('.search_city'+ employmentHistoryCount).on('keyup',function(){
    //     $('.search_name'+ employmentHistoryCount).val('');
    // })

   

    // $('.search_name'+ employmentHistoryCount).on('click', function() {
    //     console.log("Done");
    //     let data_id = $(this).attr("id");
    //     //console.log(data_id);
    //  $('#' + data_id).on('keyup',function(){
    //         console.log('name');
    //         console.log(data_id);
    //         let name = $(this).val();
    //         let city = $(this).parent().next().find('.search_city'+ employmentHistoryCount).val();
    //         //let city = $('#employerCity'+ employmentHistoryCount).val();
    //         console.log(name, city)
       
    //         ajaxSearch(name, city);
    //         // console.log(city);
    //     })
        
    // });

    // $('.search_city'+ employmentHistoryCount).on('click', function() {
    //     console.log("Done");
    //     let data_id = $(this).attr("id");
    //     // //  console.log(data_id);
    //     $('#' + data_id).on('keyup',function(){
    //         console.log("city");
    //         console.log(data_id);
    //         let city = $(this).val();
    //          let name = $(this).parent().prev().find('.search_name'+ employmentHistoryCount).val();
        
    //         //let name = $('#employerName'+ employmentHistoryCount).val();
    //         console.log(name, city)
    //         ajaxSearch(name, city);
    //     })
      
    // });

    // $('.searchbtn'+ employmentHistoryCount).on('click', function(e) {
        // console.log("done");
        // let spinner = $(this).parent().prev().prev().prev().find('.small'+employmentHistoryCount);
        // let name = $(this).parent().prev().prev().find('.search_name'+employmentHistoryCount).val();
        // let city = $(this).parent().prev().find('.search_city'+employmentHistoryCount).val();
        // let ul = $(this).parent().next().find('.data'+employmentHistoryCount).attr('id');
        // // console.log(name);
        // // console.log(city);
        // console.log(spinner);
        // // e.preventDefault();
        // // $('#spinner').show();
        // // ajaxSearch(name, city, ul);
        // // console.log(ul);
    // })

    $(document).ready(function() {
        logId = '';
         $('.myDiv input').change(function() {
             searchId = $(this).parent().parent().parent().parent().parent().parent().attr('id');
             inputId = $(this).parent().parent().parent().attr('id');
        
             if (searchId == undefined) {
                 logId = inputId;
             } else {
                logId = '';
             }
             if (inputId == undefined) {
                 logId = searchId;
             } else {
                logId = '';
             }
            //  console.log(logId);
             userId = $('#user_id').val();    
             ajaxUpdate(logId, userId);
         });
        

         $('.myDiv textarea').change(function() {
             inputId = $(this).parent().parent().parent().attr('id');
             logId = inputId ? inputId : '';
            //  console.log(logId);
             userId = $('#user_id').val();    
             ajaxUpdate(logId, userId);
         });

   
     });

     const ajaxUpdate = (logId, userId) => {
        $.ajax({
            url: '/update-log-activity/' + '?logid=' + logId + '&userid=' +
                userId,
            type: 'GET',
        })
    }

    let ulId = null;
    let xhr = null;

    $(document).on('click', '.searchbtn'+employmentHistoryCount, function () {
        // console.log("done");
        // console.log(logId);
        let name = $(this).parent().prev().prev().find('.search_name[name="employer_name[]"]').val();
        let city = $(this).parent().prev().find('.search_city[name="employerCity[]"]').val();
        let ul = $(this).parent().next().find('.data[name="data"]');
        let  ulId = $(ul).parent().data('ulid');
        if (typeof ulId === 'undefined') {
            ulId = '';
        }
        $('#spinner'+ulId).show();

        
        if (name == '' && city == '') {
          $(document).ready(function(){
            $('#spinner' + ulId).on('click',function() {
                $('#spinner' + ulId).hide();
            });
          })
        }
        // console.log(logId);
        ajaxSearch(name, city, ul,logId);
    });

    // $(document).on('click', '.clearbtn'+employmentHistoryCount, function () {

    //     if (xhr && xhr.readyState !== 4) {
    //         xhr.abort(); 
    //         xhr = null; 
    //     }

    //     let ul = $(this).parent().parent().parent().find('.data[name="data"]');

    //     let  ulId = $(ul).parent().data('ulid');
    //     if (typeof ulId === 'undefined') {
    //         ulId = '';
    //     }
    //     $('#spinner'+ulId).hide();

    // });


  
    const ajaxSearch = (name, city,ul,logId) => {
        xhr =  $.ajax({
                url: '/search/dotn/server2',
                type: 'GET',
                data: {
                    'name': name,
                    'city': city,
                },
                success: function(data) {
                    data = data.results;
        
                    // console.log(data);
                    //let dataid = $('.data'+employmentHistoryCount).attr('id');
                    ulId = $(ul).parent().data('ulid');
                    if (typeof ulId === 'undefined') {
                        ulId = '';
                    }
                    $('.erase'+ employmentHistoryCount).remove()
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                        ul.after(
                                `<li class="mt-2 erase`+employmentHistoryCount+` result`+employmentHistoryCount+`" data-id="${data[i].dot_number}" data-database="${data[i].database}" id="employer_name">${data[i].text}</li>
                                <input type="hidden" class="dbname`+ employmentHistoryCount+`" data-id="${data[i].database}">`
                            );

                            // $('#' + dataid).after(
                            //     `<li class="mt-2 erase`+employmentHistoryCount+` result`+ employmentHistoryCount+`" data-id="${data[i].dot_number}" data-database="${data[i].database}" id="employer_name">${data[i].text}</li>
                            //     <input type="hidden" class="dbname`+ employmentHistoryCount+`" data-id="${data[i].database}">`
                            // );
                            
                        
                        }  
                    } else {
                        ul.after(
                            `<p class="mt-2 erase`+ employmentHistoryCount +`">Data Not found</p>`
                        );
                        
                    }
        
                    
        
                    $('.result'+ employmentHistoryCount).on('click',function() {
                    
                        let userId = $('#user_id').val();
                    
                        $('#spin'+ulId).show();
                        $('.erase'+employmentHistoryCount).remove();
                        $('#last_active_element').val(logId);
                        id = $(this).data('id');
                        database = $(this).data('database');
                        $.ajax({
                            url: '/get-dot-detail/' + id + '?logid=' + logId  +'&userid='+ userId +'&type='+ database,
                            type: 'GET',
                            success: function(data) {
        
                                console.log(data);
        
                                address = data.data.PHY_STREET + " " + data.data
                                    .PHY_CITY + " " + data.data.PHY_STATE + " " + data
                                    .data.PHY_COUNTRY + " " + data.data.PHY_ZIP
        
                                $("#employerName" + ulId).val(data.data.LEGAL_NAME);
                                $("#employerCity"+ ulId).val(data.data.PHY_CITY);
                                $("#dotCity"+ ulId).val(data.data.PHY_CITY);
                                $("#manualEmployerName"+ ulId).val(data.data.LEGAL_NAME);
                                $("#employerAddress"+ ulId).val(address);
                                $("#employerPhone"+ ulId).val(data.data.TELEPHONE);
                                $("#employerEmail"+ ulId).val(data.data.EMAIL_ADDRESS);
                                $("#dotNo"+ ulId).val(data.data.DOT_NUMBER);
                            },
                            complete: function() {
                                $('#spin'+ulId).hide();
                                xhr = null;
                            }
                        })
                    });
        
                },
                complete: function() {
                    $('#spinner'+ulId).hide();
                    xhr = null;
                }
            })
     }
    /**
     * select2 initialize on newly created employement experience dotN dropdown
     */
  
    // $('#employerName-'+employmentHistoryCount).select2({
    
        // width: 700,
        // ajax: {
        //     url: '/search/dotn/server2',
        //     type: "get",
        //     dataType: 'json',
        //     delay: 250,
        //     data: function (params) {
        //         return {
        //             search: params.term, // search term
        //             page: params.page || 1,
        //             type: $('#search_type').val()
        //         };
        //     },
        //     processResults: function (response) {
        //         return {
        //             results: response.results,
        //             pagination: {
        //                 more: response.pagination.more
        //             }
        //         }
        //     },
        //     transport: function (params, success, failure) {
            
        //         // $('.employer_spinner').removeClass('d-none');
        //         $('#employerName-'+employmentHistoryCount).prev().find('.employer_spinner').show();
        //         var $request = $.ajax({
        //             ...params,
        //             success: function(data) {
        //                 // $('.employer_spinner').addClass('d-none');
        //                 $('#employerName-'+employmentHistoryCount).prev().find('.employer_spinner').hide();
        //             }
        //         });
    
        //         $request.then(success);
    
        //         $request.fail(failure);
            
        //         return $request;
        //       },
        //     cache: true
        // }

   // });

    if($('#search_type').val() == 'global') {
        $('.search_employe_global').prop("checked",true);
        } 
        else{
        $('.search_employe_local').prop("checked",true);
        }
    });

   

/**
 * remove an employment history section trigger on click function on application form
 */
$('#remove-new-employment-history').on('click', function () {
    $('.dynamic-employment-history-div section.employmentHistoryCardBody:last').remove();
    employmentHistoryCount--;
});




var employmentRegisterCount = $('#employmentRegisterCount').val() ? $('#employmentRegisterCount').val() - 1 : 0;

$('#add-new-register-info').on('click', function () {
    employmentRegisterCount++;
    $('.dynamic-register-info').append(`
            <div class="section" id="`+ employmentRegisterCount+`">
                <div class="row content mt-4"
                    style="background: #80808069; margin-left: 0px; margin-right: 0;padding: 10px 0;">

                    <div id="spinner" class="spinner">
                        <div class="spinner-inner"></div>
                    </div>  
                    <div>
                        <label style="color: #4a3e3e;font-family: 'Nunito', sans-serif;font-weight: 400">We are searching millions of records; loading can take a minute.</label>
                    </div>

                    <div class="col-md-5">
                        <label for="searchEmployerName" class="form-label">Name</label>
                        <input name="employer_name[]" id="searchEmployerName`+ employmentRegisterCount+`"
                            class="form-control search_name" type="search">
                    </div>
                    <div class="col-md-5">
                        <label for="employerCity" class="form-label">City</label>

                        <input name="employerCity[]" id="employerCity`+ employmentRegisterCount+`"
                            class="form-control search_city" type="search">
                    </div>

                    <div class="col-md-2">

                        <button type="button" class="btn btn-success searchbtn`+ employmentRegisterCount+`"
                            style="margin-top: 2rem">Search</button>
                    </div>



                    <div class="col-md-12" data-ulid="`+ employmentRegisterCount+`">
                        <ul class="data" name="data" id="data`+ employmentRegisterCount+`"></ul>
                    </div>
                </div>

                <div class="form-group col-md-12">
                    <label for="employerName">Employer Name</label>
                    <input type="text" class="form-control mt-2" id="employerName`+ employmentRegisterCount+`">
                </div>

                <div class="mt-2 mb-2 form-group col-md-6">
                    <label>Subject to FMCSA: </label>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="subject_to_fmcsa`+ employmentRegisterCount+`"
                            id="fmcsa0`+ employmentRegisterCount+`" value="1">
                        <label class="form-check-label" for="fmcsa0`+ employmentRegisterCount+`">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="subject_to_fmcsa`+ employmentRegisterCount+`"
                            id="fmcsa1`+ employmentRegisterCount+`" value="0" checked>
                        <label class="form-check-label" for="fmcsa1`+ employmentRegisterCount+`">No</label>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="employerAddress">Address</label>
                        <input type="text" class="form-control address" id="employerAddress`+ employmentRegisterCount+`" name="employer_address" value="">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="employer_Phone">Phone</label>
                        <input type="text" class="form-control" id="employer_Phone`+ employmentRegisterCount+`"
                            name="employerPhone" value="">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="employer_Email">Email</label>
                        <input type="text" class="form-control" id="employer_Email`+ employmentRegisterCount+`"
                            name="employer_email" value="">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="dotNo">DOT Number or Customer ID</label>
                        <input type="text" class="form-control" id="dotNo`+ employmentRegisterCount+`" name="employer_id[]">
                    </div>
                </div>
            </div>
    `);


    $(document).on('click', '.searchbtn'+employmentRegisterCount, function () {
        // console.log("done");
       
        let name = $(this).parent().prev().prev().find('.search_name[name="employer_name[]"]').val();
        let city = $(this).parent().prev().find('.search_city[name="employerCity[]"]').val();
        let ul = $(this).parent().next().find('.data[name="data"]');
        let  ulId = $(ul).parent().data('ulid');
        if (typeof ulId === 'undefined') {
            ulId = '';
        }
        $('#spinner'+ulId).show();

        
        if (name == '' && city == '') {
          $(document).ready(function(){
            $('#spinner' + ulId).on('click',function() {
                $('#spinner' + ulId).hide();
            });
          })
        }
       
        ajaxSearch(name, city, ul);
    });


    const ajaxSearch = (name, city,ul) => {
        $.ajax({
            url: '/search/dotn/server2',
            type: 'GET',
            data: {
                'name': name,
                'city': city,
            },
            success: function(data) {
                data = data.results;
    
                // console.log(data);
                //let dataid = $('.data'+employmentRegisterCount).attr('id');
                ulId = $(ul).parent().data('ulid');
                if (typeof ulId === 'undefined') {
                    ulId = '';
                }
                $('.erase'+ employmentRegisterCount).remove()
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                       ul.after(
                            `<li class="mt-2 erase`+employmentRegisterCount+` result`+employmentRegisterCount+`" data-id="${data[i].dot_number}" data-database="${data[i].database}" id="employer_name" style="cursor:pointer">${data[i].text}</li>
                            <input type="hidden" class="dbname`+ employmentRegisterCount+`" data-id="${data[i].database}">`
                        );

                        // $('#' + dataid).after(
                        //     `<li class="mt-2 erase`+employmentRegisterCount+` result`+ employmentRegisterCount+`" data-id="${data[i].dot_number}" data-database="${data[i].database}" id="employer_name">${data[i].text}</li>
                        //     <input type="hidden" class="dbname`+ employmentRegisterCount+`" data-id="${data[i].database}">`
                        // );
                        
                    
                    }  
                } else {
                    ul.after(
                        `<p class="mt-2 erase`+ employmentRegisterCount +`">Data Not found</p>`
                    );
                    
                }
    
                
    
                $('.result'+ employmentRegisterCount).on('click',function() {
                  
                    let userId = $('#user_id').val();
                   
                    $('#spin'+ulId).show();
                    $('.erase'+employmentRegisterCount).remove();
                    
                    id = $(this).data('id');
                    database = $(this).data('database');
                    $.ajax({
                        url: '/get-dot-detail/' + id +'?userid='+ userId +'?type='+ database,
                        type: 'GET',
                        success: function(data) {
    
                            // console.log(data);
    
                            address = data.data.PHY_STREET + " " + data.data
                                .PHY_CITY + " " + data.data.PHY_STATE + " " + data
                                .data.PHY_COUNTRY + " " + data.data.PHY_ZIP
                            
                            $("#searchEmployerName" + ulId).val(data.data.LEGAL_NAME);
                            $("#employerCity"+ ulId).val(data.data.PHY_CITY);
                            $("#employerName" + ulId).val(data.data.LEGAL_NAME);
                            $("#employerAddress"+ ulId).val(address);
                            $("#employer_Phone"+ ulId).val(data.data.TELEPHONE);
                            $("#employer_Email"+ ulId).val(data.data.EMAIL_ADDRESS);
                            $("#dotNo"+ ulId).val(data.data.DOT_NUMBER);
                        },
                        complete: function() {
                            $('#spin'+ulId).hide();
                        }
                    })
                });
    
            },
            complete: function() {
                $('#spinner'+ulId).hide();
            }
        })
     }


    
    
    });




$('#remove-new-register-info').on('click', function () {
    $('.section:last').remove();
    employmentRegisterCount--;
});

/**
 * get details for selected employer DOTN
 */
// $(document).on('change', '.employerName', function () {

//     var selectedOption = $(this).select2('data');
//     var id = selectedOption[0]['id'];
//     var value = selectedOption[0]['text'];

//     var base_path = $("#url").val();
//     var url = base_path + "/get-dot-detail/" + id;
//     var manualEmployerName = $(this).parent().parent().find('#manualEmployerName[name="manual_employer_name[]"]');
//     var address = $(this).parent().parent().next().find('#employerAddress[name="employer_address[]"]');
//     var phone = $(this).parent().parent().next().find('#employerPhone[name="employer_phone[]"]');
//     var email = $(this).parent().parent().next().find('#employerEmail[name="employer_email[]"]');
//     var dotNo = $(this).parent().next().next().find('#dotNo[name="dot_no[]"]');
//     var dotStreet = $(this).parent().next().next().find('#dotStreet[name="dot_street[]"]');
//     var dotCity = $(this).parent().next().next().find('#dotCity[name="dot_city[]"]');
//     var dotState = $(this).parent().next().next().find('#dotState[name="dot_state[]"]');
//     var dotZip = $(this).parent().next().next().find('#dotZip[name="dot_zip[]"]');
//     var dotCountry = $(this).parent().next().next().find('#dotCountry[name="dot_country[]"]');

//     $.ajax({
//         url: url,
//         type: 'get',
//         data: {
//             type: $('#search_type').val()
//         },
//         dataType: 'json',
//         success: function (response) {
//             if (response != null) {
//                 manualEmployerName.val(response.data.LEGAL_NAME ? response.data.LEGAL_NAME : '');
//                 address.val(response.data.PHY_STREET + ', ' +
//                     response.data.PHY_CITY + ', ' + response.data.PHY_STATE + ', ' +
//                     response.data.PHY_ZIP + ', ' +
//                     response.data.PHY_COUNTRY);
//                 phone.val(response.data.TELEPHONE ? response.data.TELEPHONE : '');
//                 email.val(response.data.EMAIL_ADDRESS ? response.data.EMAIL_ADDRESS : '');
//                 dotNo.val(response.data.DOT_NUMBER ? response.data.DOT_NUMBER : '');

//                 dotStreet.val(response.data.PHY_STREET ? response.data.PHY_STREET : '');
//                 dotCity.val(response.data.PHY_CITY ? response.data.PHY_CITY : '');
//                 dotState.val(response.data.PHY_STATE ? response.data.PHY_STATE : '');
//                 dotZip.val(response.data.PHY_ZIP ? response.data.PHY_ZIP : '');
//                 dotCountry.val(response.data.PHY_COUNTRY ? response.data.PHY_COUNTRY : '');
//             }
//         }
//     });
// });

$(document).on('change', 'input[type=radio][name*="subject_to_fmcsa"]', function () {
    if (this.value == 1) {
        // var returnVal = confirm("Are you sure?");
        $(this).prop("checked", 1);
        var dotNo = $(this).parent().parent().prev().find('#dotNo[name="dot_no[]"]');
        dotNo.removeAttr('readonly');
    } else {
        var dotNo = $(this).parent().parent().prev().find('#dotNo[name="dot_no[]"]');
        dotNo.prop('readonly', true);
    }
});


    // $('.search_employe_global').click(function(){
    //     $('.search_employe_global').prop("checked",true)
    //     $('.search_employe_global').change(function(){
    //         $('.search_employe_global').prop("checked",true)
    //     })
    // });
    // $('.search_employe_local').click(function(){
    //     $('.search_employe_local').prop("checked",true)
    //     $('.search_employe_local').change(function(){
    //         $('.search_employe_local').prop("checked",true)
    //     })
    // });


/**
 * document ready function to load necessary jquery on DOM
 */
// $(function () {
//     // ===== prevent form submission on enter key press
//     $(document).on("keydown", ":input:not(textarea)", function (event) {
//         return event.key != "Enter";
//     });
//     // =========== Signature =============

//     var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
//         backgroundColor: 'rgba(255, 255, 255, 0)',
//         penColor: 'rgb(0, 0, 0)'
//     });
//     var saveButton = document.getElementById('save');
//     var cancelButton = document.getElementById('clear');

//     saveButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         var data = signaturePad.toDataURL('image/png');
//         $('input[name="applicant_signature"]').val(data);

//     });

//     cancelButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         signaturePad.clear();
//     });

//     // =========== Signature =============

//     var psignaturePad = new SignaturePad(document.getElementById('previous_employeer_signature-pad'), {
//         backgroundColor: 'rgba(255, 255, 255, 0)',
//         penColor: 'rgb(0, 0, 0)'
//     });
//     var psaveButton = document.getElementById('previous-employeer-signature-save');
//     var pcancelButton = document.getElementById('previous-employeer-signature-clear');

//     psaveButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         var data = psignaturePad.toDataURL('image/png');
//         $('input[name="previous_employeer_signature"]').val(data);

//     });

//     pcancelButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         psignaturePad.clear();
//     });

//     // =========== Signature =============

//     var p3signaturePad = new SignaturePad(document.getElementById('previous_employeer_signature-pad-p3'), {
//         backgroundColor: 'rgba(255, 255, 255, 0)',
//         penColor: 'rgb(0, 0, 0)'
//     });
//     var p3saveButton = document.getElementById('previous-employeer-signature-save-p3');
//     var p3cancelButton = document.getElementById('previous-employeer-signature-clear-p3');

//     p3saveButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         var data = p3signaturePad.toDataURL('image/png');
//         $('input[name="previous_employeer_signature_p3"]').val(data);

//     });

//     p3cancelButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         p3signaturePad.clear();
//     });

//     // =========== Signature =============

//     var p5signaturePad = new SignaturePad(document.getElementById('driver_signature-pad-p5'), {
//         backgroundColor: 'rgba(255, 255, 255, 0)',
//         penColor: 'rgb(0, 0, 0)'
//     });
//     var p5saveButton = document.getElementById('driver-signature-save-p5');
//     var p5cancelButton = document.getElementById('driver-signature-clear-p5');

//     p5saveButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         var data = p5signaturePad.toDataURL('image/png');
//         $('input[name="driver_signature_p5"]').val(data);

//     });

//     p5cancelButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         p5signaturePad.clear();
//     });

//     // =========== Signature =============

//     var p6signaturePad = new SignaturePad(document.getElementById('employeer_signature-pad-p6'), {
//         backgroundColor: 'rgba(255, 255, 255, 0)',
//         penColor: 'rgb(0, 0, 0)'
//     });
//     var p6saveButton = document.getElementById('employeer-signature-save-p6');
//     var p6cancelButton = document.getElementById('employeer-signature-clear-p6');

//     p6saveButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         var data = p6signaturePad.toDataURL('image/png');
//         $('input[name="employeer_signature_p6"]').val(data);

//     });

//     p6cancelButton.addEventListener('click', function (event) {
//         event.preventDefault();
//         p6signaturePad.clear();
//     });

// });

/**
 * select2 dynamic ajax for dotN search
 */
$(".js-data-example-ajax").select2({
    width: 700,
    ajax: {
        url: '/search/dotn/server2',
        type: "get",
        dataType: 'json',
        // delay: 250,
        data: function (params) {
            return {
                search: params.term, // search term
                page: params.page || 1,
                type: $('#search_type').val()
            };
        },
        processResults: function (response) {
            return {
                results: response.results,
                pagination: {
                    more: response.pagination.more
                }
            }
        },
        transport: function (params, success, failure) {
            
            // $('.employer_spinner').removeClass('d-none');
            $('.employer_spinner.first').show();
            var $request = $.ajax({
                ...params,
                success: function(data) {
                    // $('.employer_spinner').addClass('d-none');
                    $('.employer_spinner.first').hide();
                }
            });

            $request.then(success);

            $request.fail(failure);
        
            return $request;
          },
        cache: true
    }

});

/**
 * Windows print action
 */
$('.print-pdf').on('click', function () {
    window.print();
});








